import React from 'react';
import './main.scss';
import splashSrc from './splash.png';


class Splash extends React.Component {
  constructor(props){
    super(props);

    this.img = new Image();
    this.state = {
      imgWidth:0,
      imgHeight:0,
      width:0,
      height:0,
      horizontal:0
    }
    this.updateDim = this.updateDim.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount(){
    this.updateDim();
    window.addEventListener('resize', this.updateDim);
  }

  updateDim(){

    this.setState({width:window.innerWidth,height:window.innerHeight})
    if(this.state.width/this.state.height < this.state.imgWidth/this.state.imgHeight){
      this.setState({horizontal:true});
    }else {
      this.setState({horizontal:false});
    }
    // console.log(this.state,this.state.width/this.state.height,this.state.imgWidth/this.state.imgHeight);
  }

  async onImgLoad({target:img}) {
    await this.setState({imgHeight: img.offsetHeight, imgWidth: img.offsetWidth});
    this.updateDim();
  }
  render(){
    return (
      <div className = "splash">
        <p className = "splashText">{"Alex Salazar"}</p>
        <div className = "splashImgContainer">
          <img onLoad = {this.onImgLoad} className = {this.state.horizontal?"splashImg1":"splashImg2"} src = {splashSrc} alt="Logo"></img> 
        </div>
      </div>
  )
}
}

export default Splash;
