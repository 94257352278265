import React from 'react';
import './main.scss';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
//new version
class Navbar extends React.Component {

  constructor(props){
    super(props);
    this.scrollTo = this.scrollTo.bind(this);
    this.nav = React.createRef();
    this.state = {
      rendered:false,
    }
    this.path = [0];
    this.scrollPos = 0;
    this.handleResize = this.handleResize.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.findPosition = this.findPosition.bind(this);
  }
  componentDidMount(){
    //window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    
    
    this.setState({ width: this.nav.current.clientWidth});
    this.setState({ height: this.nav.current.clientHeight});
    
    // if(this.nav.current.clientWidth < 550){
    //   this.setState({ mobile: true});
    // }else{
    //   this.setState({ mobile: false});
    // }
    window.addEventListener('beforeunload', async (evt)=>{
      evt.preventDefault();
      window.scroll({top:0});
      this.scrollPos = 0;
    });
  }
  componentDidUpdate(){
  }
  componentWillUnmount(){
    //window.removeEventListener('scroll', this.handleScroll);
  }
  
  handleResize(){
    
    // if(this.state.mobile != this.state.width < 550){
    //   this.setState({ mocbile: !this.state.mobile});
    // }
    
    this.path =
      [0,
      this.props.aboutRef.current.offsetTop,
      this.props.worksRef.current.offsetTop,
      this.props.contactRef.current.offsetTop];
    //console.log(this.path);
  }

  openMenu(){
    this.setState({ open: !this.state.open});
  }

  scrollTo(Top){
    //console.log(Top,this.state.height);
    var height = this.state.height>550?this.state.height:0;
    window.scroll({
      left:0,
      top:Top-height,
      behavior:"smooth"});
  }
  findPosition(){
    // console.log("pos",window.pageYOffset);
  }
  down(){
    if(this.state.rendered===false){
      this.setState({rendered:true});
      this.handleResize();
    }
    this.findPosition();
    let nextPoint = this.scrollPos + 1 > this.path.length-1?this.scrollPos:this.scrollPos+1;
    // console.log('next',nextPoint,'path',this.path);
    this.scrollTo(this.path[nextPoint]);
    // console.log(this.scrollPos);
    this.scrollPos = nextPoint;
  }
  up(){
    
    if(this.state.rendered===false){
      this.setState({rendered:true});
      this.handleResize();
    }
    this.findPosition();
    let nextPoint = this.scrollPos - 1 <0?0:this.scrollPos-1;

    this.scrollTo(this.path[nextPoint]);
    // console.log(this.scrollPos);
    this.scrollPos = nextPoint;
  }
  
  render(){
    
  return (
    
    <div id = "navContainer" ref = {this.nav}>
      
        <span onClick = {()=>{this.up()}} className = "navItem">
          <IosArrowUp fontSize = "30px"/>
        </span>
        <span onClick = {()=>{this.down()}} className = "navItem">
          <IosArrowDown fontSize = "30px"/>
        </span>
        
    </div>
  )
  }
}



export default Navbar;
