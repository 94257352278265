import React from 'react';
import './main.scss';
import Splash from './Splash';
import Navbar from './Navbar';
import Contact from './contact';
import portrait from './portrait.JPEG';
import opioidWork from './s2.png';
// import bitWork from './s1.png';
// import aeszWork from './aeszlogo.png';
import memeWork from './memelogo.png';
import kaysenWork from './kaysensurfboards.png';
import cheesecakeWork from './cheesecake.jpeg';
import evWork from './evLogo.svg';
import '@ionic/react/css/core.css';

class App extends React.Component {
  constructor(){
    super();
    this.aboutRef = React.createRef();
    this.contactRef = React.createRef();
    this.worksRef = React.createRef();
  }
  
  render () {
    return (
    <div className="mainContainer">
      {/* <div className = "topNavigation">
        <div style = {{textAlign:"right"}}id = "topNavButton" className = "topNavTab"
          onClick = {()=>{window.location = ("/")}}>
          Home
        </div>
        <div className = "topNavTab">
          |
        </div>
        <div id = "topNavButton" className = "topNavTab"
        onClick = {()=>{alert("soon to be implemented!")}}>
          Blog
        </div>
      </div> */}
      <Navbar contactRef = {this.contactRef} worksRef = {this.worksRef} aboutRef = {this.aboutRef}></Navbar>
      <Splash/>
      <div ref = {this.aboutRef} className = "about" >
        <div className = "miscTitle">About Me</div>
        <div className = "aboutInnerContainer">
          <div className = "worksImgCont">
            <img alt ="portrait" className = "worksImg" src = {portrait}></img>
          </div>
          <div className = "worksDesc">
            <div style ={{verticalAlign:"center",display:"flex",flexDirection:"row"}}>
              <ion-icon style={{marginRight:"10px",color:"gold"}} size ="large" name="school"></ion-icon> 
              <div style={{textAlign:'center',}}>
                Computer Engineering - June 2021, UCI
              </div>
            </div>
            <div style = {{verticalAlign:"center",display:"flex",flexDirection:"row"}}>
              <ion-icon style={{marginRight:"10px",color:"pink"}} size ="large" name="hammer-outline"/>
              React, React Native, Javascript, HTML, CSS, C, Python, Java
            </div>
            <div style ={{verticalAlign:"center",display:"flex",flexDirection:"row"}}>
              <ion-icon style={{marginRight:"10px",color:"lightgreen"}} size ="large" name="checkmark-circle" ></ion-icon>
            <div>
            Consistently shown capabilities to lead and deliver results
              </div>
            </div>
            <div style = {{verticalAlign:"center",display:"flex",flexDirection:"row"}}>
            <ion-icon style={{marginRight:"10px",color:"turquoise"}} size ="large" name="tv"/>
            Looking for Web/Mobile Developer position
            </div>
          </div>
        </div>
        <a href='/Resume-Alexander Salazar.pdf' className = "miscTitle">Download Resume</a>
      </div>
      <div ref = {this.worksRef} className = "myWorks" >
        <div className = "miscTitle">Projects</div>
        {work("The Cheesecake Connect",cheesecakeWork,"Website frontend and backend using Next.js - seamless ordering process integrated with Strip API's","https://www.thecheesecakeconnect.com","May 2022-Present")}
        {work("Meme Champion",memeWork,"Developing a fully functional social media website centered around sharing memes. Users can sign up and share their funniest memes to find out who is the meme champion. Users are able to comment and like posts. They can manage their profile and visit other users' profiles. Still in progress and plan to incorporate many new features such as Meme Contests and ranking users on a leaderboard.","https://www.memechampion.com","August 2021")}
        {work("EV Masonry",evWork,"Website frontend for advertising masonry business","http://evmasonryconstruction.com/","February 2022")}
        {work("Kaysen Surfboards", kaysenWork, "Built website with React.js, Next.js for client. The website's purpose is to sell more surfboards, provide a professional look and offer the client an easy and adaptable solution to upload new inventory and reviews.","https://kaysensurfboards.net/","March 2020")}
        {work("Opioid Care",opioidWork,"Lead a team of 4 undergraduate students in creating an app using React Native. The app connects individuals with opioid abuse disorder to treatments facilities and providers near them using react-navigation.","https://github.com/asalaza6/Opioid-Hackathon-2019","October 2019")}
        
      </div>
      <div ref = {this.contactRef}>
        <Contact/>
      </div>
    </div>)
  }
}

function work(title,workImg,desc,link,date){
  return(
    <div className="worksContainer">
      <div className="worksTitle">{title}</div>
      <div className = "worksDate">{date}</div>
      <div className = "worksInnerContainer">
        <div className = "worksImgCont">
        <img alt= {title+"img"} className = "worksImg" onClick = {()=>window.open(link)}  src = {workImg} href={link}/>
        </div>
        
        <div className = "worksDesc">{desc}</div>
      </div>
    </div>
  );
}
export default App;
