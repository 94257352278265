import React from 'react';
import './main.scss';
import {SocialIcon} from 'react-social-icons';

class App extends React.Component {

    componentDidMount(){
        this.mainRef = this.props.passRef;
    }

	render() {
  return (
    <div className="contactContainer">
        <p className="miscTitle">Social Media</p>
        <div className= "contactInner">
            <div className="innerSocial">
                    
                    <SocialIcon style={{margin:"15px"}} url="https://github.com/asalaza6"/>
                
                    <SocialIcon style={{margin:"15px"}} url="https://www.linkedin.com/in/asalaza6/"/>
                
                    <SocialIcon style={{margin:"15px"}} url="mailto:asalaza6@uci.edu"/>
            </div>

	        
	    </div>
        <p className = "Copyright">Copyright 2019 - All Rights Reserved</p>
    </div>
  )
	}
}

export default App;
